/*
* tabs
*   tab-selector (tab-select) (tab-select) (tab-select)
*   tab
*   tab
*   tab
* */

(fn => (document.readyState !== 'loading') ? fn() : document.addEventListener('DOMContentLoaded', fn))(()=> {
  document.querySelectorAll('.tabs').forEach(tabs => {
    tabs.querySelectorAll('.tab-selector > .tab-select').forEach(btn => btn.addEventListener('click', ev =>{
      ev.preventDefault();
      //change tabs class
      tabs.setAttribute('data-tab', btn.getAttribute('data-tab'))
    }))
  })
});